// src/components/Register.js
import React, { useState } from "react";
import axios from "axios";
import { Form, Input, Button, Row, Col, Alert, Select } from "antd"; // Importar Select para la lista desplegable
import "antd/dist/reset.css";
import "../Components/assets/styles.css";

const { Option } = Select; // Para las opciones de Select

function Register() {
  const [formData, setFormData] = useState({
    idCabeza: "",
    Vacuna: "",
    peso: "",
    edad: "",
    raza: "",
    genetica: "",
    enfermedades: "",
    rancho: "",
    notas: "",
  });

  const [alert, setAlert] = useState(null); // Estado para manejar la alerta

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/animals`,
        formData
      );
      setAlert({ type: "success", message: "Datos enviados correctamente" });
    } catch (error) {
      setAlert({ type: "error", message: "Error al enviar los datos" });
    }
  };

  return (
    <div className="container mx-auto mt-5" style={{ padding: "20px" }}>
      <h1 className="text-3xl font-bold mb-4 text-center">Registrar Ganado</h1>

      {/* Alerta condicional */}
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          showIcon
          closable
          className="mb-4"
        />
      )}

      <Form onSubmit={handleSubmit} className="w-75 mx-auto">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="ID Cabeza">
              <Input
                name="idCabeza"
                value={formData.idCabeza}
                onChange={handleChange}
                maxLength={10}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Vacuna">
              <Input
                name="Vacuna"
                value={formData.Vacuna}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Peso (kg)">
              <Input
                name="peso"
                type="number" // Solo números
                value={formData.peso}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Edad (años)">
              <Input
                name="edad"
                type="number" // Solo números
                value={formData.edad}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Raza">
              <Select
                value={formData.raza}
                onChange={(value) => handleSelectChange(value, "raza")}
                placeholder="Selecciona una raza"
              >
                <Option value="Hereford">Hereford</Option>
                <Option value="Charolais">Charolais</Option>
                <Option value="Brahman">Brahman</Option>
                <Option value="Nelore">Nelore</Option>
                <Option value="Pardo Suizo Europeo">Pardo Suizo Europeo</Option>
                <Option value="Indobrasil">Indobrasil</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Genética">
              <Input
                name="genetica"
                value={formData.genetica}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enfermedades">
              <Input
                name="enfermedades"
                value={formData.enfermedades}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Rancho">
              <Input
                name="rancho"
                value={formData.rancho}
                onChange={handleChange}
                maxLength={50}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Notas">
          <Input.TextArea
            name="notas"
            value={formData.notas}
            onChange={handleChange}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form>
    </div>
  );
}

export default Register;
