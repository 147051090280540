import React from 'react';
import { Button } from 'antd'; // Componente Button de Ant Design
import { Link } from 'react-router-dom'; // Importar Link para la navegación
import 'antd/dist/reset.css'; // Estilos de Ant Design
import './styles.css'; // Archivo CSS personalizado

function Home() {
  return (
    <div className="home-container">
      <div className="content-wrapper">
        <h1 className="home-title">Bienvenido a Corral Digital</h1> 
        <p className="home-subtitle">
          Gestión inteligente de tu ganado. Mantén un control detallado y eficiente de tu rancho.
        </p> 
        <div className="home-buttons">
          <Link to="/list">
            <Button type="primary" className="home-btn">Ver Ganado</Button> 
          </Link>
          <Link to="/register">
            <Button type="default" className="home-btn">Registrar nuevo</Button> 
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
