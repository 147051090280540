import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Alert, Button } from "antd"; // Importar componentes de Ant Design
import { Link } from "react-router-dom"; // Importar Link para la navegación
import "antd/dist/reset.css"; // Importar estilos de Ant Design
import "./styles.css"; // Archivo CSS personalizado

function List() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/animals`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error al cargar los datos");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const columns = [
    { title: "ID Cabeza", dataIndex: "idCabeza", key: "idCabeza" },
    { title: "Vacuna", dataIndex: "Vacuna", key: "Vacuna" },
    { title: "Peso", dataIndex: "peso", key: "peso" },
    { title: "Edad", dataIndex: "edad", key: "edad" },
    { title: "Raza", dataIndex: "raza", key: "raza" },
    { title: "Genética", dataIndex: "genetica", key: "genetica" },
    { title: "Enfermedades", dataIndex: "enfermedades", key: "enfermedades" },
    { title: "Rancho", dataIndex: "rancho", key: "rancho" },
    { title: "Notas", dataIndex: "notas", key: "notas" },
  ];

  return (
    <div className="table-container">
      <h1 className="table-title">Lista de Ganado</h1>

      {/* Mostrar error en caso de fallo */}
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          className="alert"
        />
      )}

      {/* Tabla para pantallas grandes */}
      <div className="table-desktop">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{ pageSize: 5 }} // Paginación con 5 registros por página
          rowKey={(record) => record.idCabeza} // Usar ID Cabeza como clave única
          bordered
          scroll={{ x: "max-content" }} // Permite el desplazamiento horizontal
        />
      </div>

      {/* Vista en formato de lista para pantallas pequeñas */}
      <div className="table-mobile">
        {data.map((item) => (
          <div className="table-row" key={item.idCabeza}>
            <div className="table-cell id-header">
              <strong>ID Cabeza:</strong> {item.idCabeza}
            </div>
            {columns.slice(1).map((col) => (
              <div className="table-cell" key={col.key}>
                <strong>{col.title}:</strong> {item[col.dataIndex]}
              </div>
            ))}
          </div>
        ))}
      </div>
      <Link to="/register">
        <Button type="default" className="home-btn">
          Registrar nuevo
        </Button>
      </Link>
    </div>
  );
}

export default List;
